import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from "../images/logo.png";
import twitterimage from "../images/mobile_text.jpg"

const Header = ({ children, siteTitle }) => (
  <header className="usa-header usa-header--extended" role="banner">
    <div className="usa-navbar">
      {console.log(twitterimage)}
      
      <div className="diplay-none">
      <div class="tablet:display-flex display-none mobile:display-none mobile-md:diplay-none mobile-sm:display-none flex-row flex-align-top margin-top-2 margin-bottom-1 ">
      <img className="" height="120"  width="200" src={logo} alt="Logo"  />
      <em className="usa-logo__text  margin-left-4 tablet:hidden">
          <a to="/" title="Home" aria-label="Home">
            <h1 class="tablet:display-flex display-none" style={{fontSize:"26px", lineHeight:'normal', marginTop:"40px"}}> Lake County State’s Attorney's <br></br>Data Dashboard </h1>
          </a>

        </em>

</div>
</div>
<div className="tablet:display-none mobile-lg:display-flex mobile:display-flex margin-left-2 margin-bottom-2 margin-top-2">
<h3 >Lake County State’s Attorney's Data Dashboard</h3>
</div>
      <div class="grid-flex-align-stretch">


        </div>
      </div>
      <button className="usa-menu-btn">Menu</button>
    {children}
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
