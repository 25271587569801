import React from 'react';
import flag from '../../node_modules/uswds/dist/img/us_flag_small.png';
import dotGov from '../../node_modules/uswds/dist/img/icon-dot-gov.svg';
//import arrowDown from '../images/download_link.svg';
import https from '../../node_modules/uswds/dist/img/icon-https.svg';

const Banner = () => (
  <div className="usa-banner">
    <div className="usa-accordion">
      <header className="usa-banner__header">
        <div className="usa-banner__inner">
          <div className="grid-col-auto">
          </div>
          <div className="grid-col-fill tablet:grid-col-auto">
            <p className="usa-banner__header-text">
            An official website of the Lake County State’s Attorney’s Office
            </p>
            <p className="usa-banner__header-action" aria-hidden="true">
            </p>
          </div>
          <button
            aria-controls="gov-banner"
            aria-expanded={false}
            className="usa-accordion__button usa-banner__button"
          >
            <span className="usa-banner__button-text">Here’s how you know</span>
          </button>
        </div>
      </header>
      <div
        id="gov-banner"
        className="usa-accordion__content usa-banner__content"
        hidden
      >
        <div className="grid-row grid-gap-lg">
          <div className="usa-banner__guidance-gov tablet:grid-col-6">
            <div className="usa-media-block__body">
              <p>
                <strong>Let Us Know</strong>
                <br />
                To provide feedback or ask questions about the site, fill out this <a style={{color:"#07648d"}} href='https://docs.google.com/forms/d/1KqujnwRz8IyudLZgpsljjPYanmeQ7DGxptZW1RXcfJ4/'>form.</a>
              </p>
              <div className="usa-banner__guidance-ssl tablet:grid-col-6">
          </div>
              
            </div> 
                       
          </div>
          <div className="usa-media-block__body ">
              <p>
                <strong>The site is secure.</strong>
                <br />
                The <strong>https://</strong> ensures that you are connecting to
                the official website and that any information you provide is
                encrypted and transmitted securely.
              </p>
            </div>
          
        </div>
        
      </div>
      
    
    </div>
  </div>
);

export default Banner;
