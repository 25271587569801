import React from 'react';
import logo from "../images/logo.png";

const Footer = () => (
<footer class="usa-footer">
  <div class="usa-footer__primary-section">
    <nav class="usa-footer__nav" aria-label="Footer navigation">
      <ul class="grid-row grid-gap">
        <li
          class="
            mobile-lg:grid-col-4
            desktop:grid-col-auto
            usa-footer__primary-content
          "
        >
          <a class="usa-footer__primary-link" href="https://www.safetyandjusticechallenge.org/">
          Safety and Justice Challenge (SJC)
          </a>
        </li>

        <li
          class="
            mobile-lg:grid-col-4
            desktop:grid-col-auto
            usa-footer__primary-content
          "
        >
          <a class="usa-footer__primary-link" href="https://prosecutorialperformanceindicators.org/">
          Prosecutorial Performance Indicators (PPI)
          </a>
        </li>

        <li
          class="
            mobile-lg:grid-col-4
            desktop:grid-col-auto
            usa-footer__primary-content
          "
        >
          <a class="usa-footer__primary-link" href="https://isp.illinois.gov/CrimeReporting/CrimeInIllinoisReports">
          ISP Crime Reports
          </a>
        </li>
        <li
          class="
            mobile-lg:grid-col-4
            desktop:grid-col-auto
            usa-footer__primary-content
          "
        >
          <a class="usa-footer__primary-link" href="https://docs.google.com/forms/d/1KqujnwRz8IyudLZgpsljjPYanmeQ7DGxptZW1RXcfJ4/">
          Feedback
          </a>
        </li>

        <li
          class="
            mobile-lg:grid-col-4
            desktop:grid-col-auto
            usa-footer__primary-content
          "
        >
        </li>
      </ul>
    </nav>
  </div>

  <div class="usa-footer__secondary-section">
    <div class="grid-container">
      <div class="grid-row grid-gap">
        <div
          class="
            usa-footer__logo
            grid-row
            mobile-lg:grid-col-6 mobile-lg:grid-gap-2
          "
        >
          <div class="mobile-lg:grid-col-auto">
            <img
              class="usa-footer__logo-img"
              src="/assets/img/logo-img.png"
              alt=""
            />
          </div>
          <div class="mobile-lg:grid-col-auto">
          <img width="120" src={logo}></img>
            <p class="usa-footer__logo-heading">Lake County State’s Attorney</p>
            <p style={{fontSize:'12px'}}>&#169; 2021 Lake County State’s Attorney’s Office</p>
          </div>
          <div class="grid-container">
    </div>
        </div>
        <div class="usa-footer__contact-links mobile-lg:grid-col-6">
          <div class="usa-footer__social-links grid-row grid-gap-1">
            <div class="grid-col-auto">
              <a
                class="usa-social-link usa-social-link--facebook"
                href="https://www.facebook.com/LCSTATESATTORNEY"
              >
                <span>Facebook</span>
              </a>
            </div>

            <div class="grid-col-auto">
              <a
                class="usa-social-link usa-social-link--twitter"
                href="https://twitter.com/saolakecountyil"
              >
                <span>Twitter</span>
              </a>
            </div>

        
          </div>
          <h3 class="usa-footer__contact-heading">
          18 N. County St., 3rd Floor, Waukegan, IL 60085
          </h3>
          <address class="usa-footer__address">
            <div class="usa-footer__contact-info grid-row grid-gap">
              <div class="grid-col-auto">
                <a href="tel:847-377-3000"> 847-377-3000</a>
              </div>
              <div class="grid-col-auto">
                <a href="mailto:StatesAttorney@lakecountyil.gov;">
                StatesAttorney@lakecountyil.gov
                </a>                
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  </div>
</footer>
);

export default Footer;
